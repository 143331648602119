import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';

export default function Convert({ usdPrice, oldPrice }) {
  const currency = useSelector((state) => state.user.currency);

  const [uahRate, setUahRate] = useState(null);
  const [eurRate, setEurRate] = useState(null);

  useEffect(() => {
    getRate();
  }, [currency]);

  const getRate = async () => {
    try {
      const { data } = await axios.get(
        'https://api.sicvolo.org/wp-json/wp/v2/posts/?include[]=1989'
      );
      setUahRate(data[0]?.acf.usd_rate ?? uahRate);
      setEurRate(data[0]?.acf.euro_rate ?? eurRate);
    } catch (e) {
      console.error('Error fetching currency rates:', e);
    }
  };

  const uahPrice = uahRate * usdPrice;
  const eurPrice = uahRate * usdPrice;

  const convertedPrice =
    currency === 'UAH'
      ? uahPrice > 0
        ? `${(uahRate * usdPrice).toFixed(0)} UAH`
        : ''
      : currency === 'EUR'
      ? eurPrice > 0
        ? `${(eurRate * usdPrice).toFixed(2)} EUR`
        : ''
      : usdPrice > 0
      ? `${usdPrice} USD`
      : '';

  const oldConvertedPrice =
    oldPrice &&
    (currency === 'UAH'
      ? (parseFloat(oldPrice) * usdPrice).toFixed(0)
      : currency === 'EUR'
      ? (parseFloat(oldPrice) * eurRate).toFixed(0)
      : parseFloat(oldPrice).toFixed(0));

  return (
    <>
      {convertedPrice}
      {oldPrice && (
        <span className="oldPrice">
          {' '}
          {oldConvertedPrice} {currency}
        </span>
      )}
    </>
  );
}
